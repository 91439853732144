;(function () {
    'use strict';

    var modalRegisterForm = document.querySelector('.js-modal-register .js-registration');
    if (typeof modalRegisterForm !== 'undefined' && modalRegisterForm !== null)
    {
        modalRegisterForm.addEventListener('submit', function(e) {
            e.preventDefault();
            var form = this;
            gtmShop.initCheckoutStep(1, function(){
                gtmShop.completeCheckoutStep(1, {'option':'modal'}, function() {
                    form.submit();
                })
            })
        }, false);
    }

    var registerClearLinks = document.querySelectorAll('.js-register-clear');
    if (registerClearLinks.length > 0)
    {
        Array.prototype.forEach.call(registerClearLinks, function(registerClearLink, i)
        {
            onClickOrTap(registerClearLink, function(e) {
                e.preventDefault();
                window.clearRegistrationFromCart();
                window.location.replace(registerClearLink.href);
            });
        });
    }

}());
