var pushGTMSiteEvent = function(category, action, label, value, nonInteraction) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'siteEvent',
        siteEventData: {
            category: category,
            action: action,
            label: label,
            value: value,
            nonInteraction: nonInteraction
        }
    });
};

(function () {
    'use strict';

    ready(function() {

        var loadItems = window.siteEventsOnLoad;
        if (typeof loadItems !== 'undefined' && loadItems !== null) {

            Array.prototype.forEach.call(loadItems, function(loadItem, i) {
                pushGTMSiteEvent(
                    loadItem.Category,
                    loadItem.Action,
                    loadItem.Label,
                    loadItem.Value,
                    loadItem.NonInteraction
                );
            });
        }

        var gtmItems = document.querySelectorAll('[data-gtm-event-category]');

        Array.prototype.forEach.call(gtmItems, function(gtmItem, i) {

            var category = gtmItem.getAttribute('data-gtm-event-category');
            var action = gtmItem.getAttribute('data-gtm-event-action');
            var label = gtmItem.getAttribute('data-gtm-event-label');
            var value = gtmItem.getAttribute('data-gtm-event-value');
            var nonInteraction = gtmItem.getAttribute('data-gtm-event-noninteraction');

            if (typeof category === 'undefined' || category === null || category === '') {
                return;
            }

            if (typeof action === 'undefined' || action === null || action === '') {
                return;
            }

            if (typeof label === 'undefined' || label === null || label === '') {
                label = location.pathname;
                if (typeof label === 'undefined' || label === null || label === '') {
                    label = undefined;
                }
            }

            if (typeof value === 'undefined' || value === null || value === '') {
                value = undefined;
            }
            else {
                value = parseInt(value, 10);
                if (isNaN(value)) {
                    value = undefined;
                }
            }

            if (typeof nonInteraction === 'undefined' || nonInteraction === null || nonInteraction === '') {
                nonInteraction = 'false';
            }
            else if (nonInteraction !== 'true' && nonInteraction !== 'false') {
                nonInteraction = 'false';
            }

            onClickOrTap(gtmItem, function(e) {
                pushGTMSiteEvent(category, action, label, value, nonInteraction);
            });
        });
    });
}());
