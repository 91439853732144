var pushGTMVirtualHit = function(url, title, contentGroup) {
    if (typeof url === 'undefined' || url === null || url === '') {
        return false;
    }
    if (typeof title === 'undefined' || title === null || title === '') {
        title = url;
    }
    if (typeof contentGroup === 'undefined' || contentGroup === null || contentGroup === '') {
        contentGroup = null;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'siteVirtualHit',
        siteVirtualHitData: {
            url: url,
            title: title,
            contentGroup: contentGroup
        }
    });
};

(function () {
    'use strict';
    ready(function()
    {
        var virtualHits = document.querySelectorAll('[data-gtm-vhit-url]');
        Array.prototype.forEach.call(virtualHits, function(virtualHit, i)
        {
            var url = virtualHit.getAttribute('data-gtm-vhit-url');
            if (typeof url === 'undefined' || url === null || url === '') {
                return;
            }

            var title = virtualHit.getAttribute('data-gtm-vhit-title');
            var contentGroup = virtualHit.getAttribute('data-gtm-vhit-cgroup');

            onClickOrTap(virtualPage, function(e) {
                pushGTMVirtualHit(url, title, contentGroup);
            });
        });
    });
}());
