// noinspection JSVoidFunctionReturnValueUsed

;(function () {
    'use strict';

    var contentBlocks = document.querySelectorAll('.js-gtm-event-content');
    if (contentBlocks.length > 0) {
        Array.prototype.forEach.call(contentBlocks, function(contentBlock, i)
        {
            var category = contentBlock.getAttribute('data-gtm-event-content-category');
            var label = contentBlock.getAttribute('data-gtm-event-content-label');

            if (
                typeof category !== 'undefined' && category !== null
                && typeof label !== 'undefined' && label !== null
            ) {
                var anchors = contentBlock.querySelectorAll('a:not(.js-gtm-event-content-ignore)');
                if (anchors.length > 0) {
                    Array.prototype.forEach.call(anchors, function(anchor, i)
                    {
                        onClickOrTap(anchor, function(e) {
                            var path = anchor.pathname;
                            if (path === '/') {
                                path = '-home';
                            }
                            var regex = /\//ig;
                            path = path.replace(regex, '-');
                            path = path.replace(/-$/, '');
                            pushGTMSiteEvent(
                                category,
                                'click' + path,
                                label,
                                undefined,
                                'false'
                            )
                        });
                    });
                }
            }
        });
    }

}());
